import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Thumbs } from "swiper"
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { PageSeo } from "../components/PageSeo"

const EventsTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { banner } = markup.frontmatter
  const eventsArr = data.allMarkdownRemark.edges

  return (
    <Layout>
      <section className="EventsTemplate">
        <Banner data={banner} />
        <div className="container ">
          {eventsArr.map(({ node }, index) => (
            <EventsContentTemplate
              key={`eventsArr_${index}`}
              item={node.frontmatter}
              html={node.html}
              url={node.fields.slug}
            />
          ))}
        </div>
      </section>
    </Layout>
  )
}

const EventsContentTemplate = ({ item, url }) => {
  const eventslugdetails = item.EventSlugDetails
  const eventpanelimage = item.EventSlugImages.blurb
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  return (
    <div className="row my-5">
      <div className="col-12 col-lg-5">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="customSwiper"
        >
          {eventpanelimage.map((item, index) => (
            <SwiperSlide key={`eventpanelimage_${index}`}>
              <GatsbyImage
                image={item.image.childImageSharp.gatsbyImageData}
                alt={item.imageName}
                className="mb-3"
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={5}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {eventpanelimage.map((item, index) => (
            <SwiperSlide key={`eventpanelimage_${index}`}>
              <GatsbyImage
                image={item.image.childImageSharp.gatsbyImageData}
                alt={item.imageName}
                className="img-fluid"
                style={{ height: "50px" }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="col-12 col-lg-6 my-2 events-content">
        <h3 className="mb-3">{eventslugdetails.MainTitle}</h3>
        <p>
          Date : <strong>{eventslugdetails.Date}</strong>
        </p>
        <p>
          Venue : <strong>{eventslugdetails.Location}</strong>
        </p>
        <p className="mb-lg-5">{eventslugdetails.content}</p>
        <Link to={url} className="btn btn-outline-primary">
          {eventslugdetails.ButtonText}
        </Link>
      </div>
    </div>
  )
}

export const EventsQuery = graphql`
  query EventsQuery($id: String!) {
    allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___sequence }
      filter: { frontmatter: { templateKey: { eq: "events-slug" } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            name
            description
            content
            EventSlugImages {
              blurb {
                image {
                  childImageSharp {
                    gatsbyImageData(height: 250, layout: FULL_WIDTH)
                  }
                }
                imageName
              }
            }
            EventSlugDetails {
              MainTitle
              SubTitle
              Title
              Location
              Date
              content
              ButtonText
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        description
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
export default EventsTemplate
export const Head = () => (
  <PageSeo
    title="Events | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="TOAP CONVENTION 2018, TOAP ANNUAL SUMMIT 2017, Robo Advisory and Cryptocurrency to Chatbots: Disruption and Innovation at its Finest, 20/20 Vision: Gearing a Competitive Trust Industry Toward Sustainable Growth"
    keywords="TOAP CONVENTION 2018, TOAP ANNUAL SUMMIT 2017, Robo Advisory and Cryptocurrency to Chatbots: Disruption and Innovation at its Finest, 20/20 Vision: Gearing a Competitive Trust Industry Toward Sustainable Growth"
  />
)
